
import { defineComponent, reactive, PropType, watch, ref, computed } from '@vue/composition-api'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isRequired } from '@/utils/validation'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetUsersBasic } from '@/api/user'

import { ProfUnitId } from '@/api/types/profUnit'
import { OrgUnitId } from '@/api/types/orgUnit'
import { ManagementTeamMember, NewManagementTeamMember } from '@/api/types/managementTeamMember'
import { UNIT_TYPE } from '@/api/types/misc'
import { NewProfUnitManagementTeamMember } from '@/api/types/profUnitManagementTeamMember'
import { NewOrgUnitManagementTeamMember } from '@/api/types/orgUnitManagementTeamMember'
import { FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'AddEditManagementTeamMemberDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    memberToEdit: {
      type: Object as PropType<ManagementTeamMember>,
      default: null,
    },
    entityId: {
      type: Number as PropType<ProfUnitId | OrgUnitId>,
      default: null,
    },
    entityType: {
      type: String as PropType<keyof typeof UNIT_TYPE>,
      default: UNIT_TYPE.ORG_UNIT,
    },
  },
  setup: (props, { root }) => {
    const isEditMode = computed(() => Boolean(props.memberToEdit))

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    if (hasSufficientRights(Rights.BASIC_READ)) {
      getUsersBasic()
    }

    const FORM_FIELDS: FormField[] = [
      {
        value: 'user',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        isRequired: true,
        isLoading: computed(() => isLoadingUsersBasic.value),
        items: computed(() => users.value ?? []),
        rules: [(value: string) => isRequired(value, root.$t('baseData.misc.managementTeam.add.user') as string)],
      },
      {
        value: 'role',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.misc.managementTeam.add.role') as string)],
      },
    ]

    const form = ref<NewManagementTeamMember>({} as NewManagementTeamMember)

    props.entityType === UNIT_TYPE.ORG_UNIT
      ? (form.value.organizationalUnit = props.entityId)
      : (form.value.professionalUnit = props.entityId)

    watch(
      () => props.memberToEdit,
      () => {
        if (props.memberToEdit) {
          if (props.entityType === UNIT_TYPE.ORG_UNIT) {
            form.value = props.memberToEdit as NewOrgUnitManagementTeamMember
          } else {
            form.value = props.memberToEdit as NewProfUnitManagementTeamMember
          }
        }
      },
      {
        immediate: true,
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,
      },
    })
  },
})

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode
    },
    on: {
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.$emit('edit', _vm.state.form) : _vm.$emit('add', _vm.state.form);
      },
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("baseData.misc.managementTeam.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return _vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          return _c('div', {
            key: entry.value
          }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN ? _c('CommonAutocomplete', {
            class: entry.isRequired && 'required',
            attrs: {
              "items": entry.items.value,
              "item-text": "name",
              "item-value": "id",
              "loading": entry.isLoading.value,
              "label": _vm.$t('baseData.misc.managementTeam.add.user'),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t('baseData.misc.managementTeam.add.role'),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()], 1);
        });
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }